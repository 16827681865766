@import '../../assets/global.scss';
@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.Social{
  display:inline-flex;grid-column-gap:6px;
  li{list-style-type:none;}
  a, span {color:inherit;}
  a{line-height:1em;display:block;}
}

// .FbWidget{
//   max-width:500px;
//   // iframe{width:100%;border:none;overflow:hidden;}
//   position:relative;
//   width:100%;
//   padding-top:56.25%;

//   iframe{position:absolute;top:0;left:0;width:100%;height:100%;border:none;overflow:hidden;}
// }