@import '../../assets/global.scss';

.Contact{
  .v2{padding:100px 0;background-color:$bg2; overflow:hidden;position:relative;z-index:2;
    &::before{@include before();background-color:rgba($bg2, .93);z-index:1;}
    &::after{@include after();width:45%;right:0;z-index:0;
      @include sec-bgimage('./images/volleyTall.jpg');background-size:cover;
      box-shadow:inset 20px -5px 30px 50px $bg2;border-top-left-radius:30%;border-bottom-left-radius:30%;
    }

    .mainRow{@include sec-default();position:relative;z-index:2;
      &::before{@include abs();border-radius:50%;background-color:rgba(darken($tealLt, 1%), 1);
        width:330px;height:330px;left:-18%;bottom:-10px;
      }
    }    
    h1{font-size:100px;margin-bottom:40px;margin-left:-15px;line-height:1em;font-family:$body;color:$teal;}

    .formWrap, .info{display:inline-block;}
    .formWrap{width:55%;float:right;margin-right:-3%;margin-top:50px;
    }
    .info{width:42%;float:left;margin-left:-3%;
      width:48%;
      &, p{font-size:19px;}
      .item{margin-bottom:15px;}
      .item:last-child{margin-bottom:0;}
      .hours p{margin-bottom:0;}
    }
    .item:not(.soc) span{font-weight:bold;margin:0 3px;}

    @media (min-width:1500px){
      .mainRow::before{left:-21%;bottom:-10px;}
      .info{margin-left:-6%;}
      .formWrap{margin-right:-5%;}
    }
    @media (max-width:1400px){
      .mainRow::before{left:-13%;bottom:10px;width:300px;height:300px;}
    }
    @include bp(tx){
      .mainRow::before{bottom:20px;}
      .formWrap{width:53%;margin:30px -3% 30px 0;}
    }
    @include bp(tw){padding-bottom:50px;
      h1{font-size:45px;margin-left:0;margin-bottom:20px;}

      .mainRow::before{width:250px;height:250px;left:-10%;bottom:unset;top:-60px;}
      .medDef{max-width:850px;}
      .formWrap{width:60%;margin:0 -2% 0 0;}
      .info{width:37%;margin-left:-1%;
        &, p{font-size:18px;}
        .item{padding-left:20px;}
      }

      .cmail{
        a{display:block;}
        a:last-child{margin-top:15px;}
        span{display: none;}
      }
    }
    @include bp(nn){padding:55px 0 20px;
      .mainRow::before{width:110px;height:110px;left:50%;margin-left:-120px;top:-35px;}
      h1{font-size:30px;margin-bottom:10px;}
      .formWrap, .info{@include sec-default();}
      .info{text-align:center;margin-left:0;
        .item{padding-left:0;}
      }
      .formWrap{max-width:600px;@include center-block();margin-top:10px;}
      .cmail{
        a{display:inline-block;}
        a:last-child{margin-top:0px;}
        span{display:inline-block;}
      }
    }
    @include bp(og){
      .formWrap{width:95%;max-width:500px;}
    }
    @include bp(ph){padding:20px 0 40px;
      .mainRow::before{width:60px;height:60px;left:50%;margin-left:-110px;top:-15px;}
      h1{font-size:25px;margin-bottom:10px;}
      .info{
        &, p{font-size:17px;}
      }
    }

    @include bp(fi){
      .cmail{
        a{display:block;}
        a:last-child{margin-top:10px;}
        span{display:none;}
      }
    }
    @include bp(sm){
      .mainRow::before{width:60px;height:60px;left:50%;margin-left:-110px;top:-15px;}
      h1{font-size:25px;margin-bottom:10px;}
      .info{
        &, p{font-size:17px;}
      }
    }
    @media (max-width:400px){
      .mainRow::before{margin-left:-100px;}
      .loc br{display:block;}          
    }
  }
}