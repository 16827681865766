@import '../../assets/global';

.Header{@include sec-default();padding:20px 0; text-align:center;position: relative;z-index: 1;
  .medDef{max-width:1300px;}
  .navMm{@include sec-default();position:relative;}
  .navItem, .hdCol{display:inline-block;}
  .hdCol{width:33%;}
  .hdEnd{margin-top:50px;  margin-top:48px;}
  .navBlk{float:left;text-align:left;}
  .logoBlk{max-width:360px;}
  .hdContact, .hdSocial{float:right;text-align:right;}
  .conLink{font-weight:bold;}
  .hdSocial{max-height:22px;
    svg{width:22px;height:22px;}
  }

  .navItem{margin-right:30px; font-weight:bold;
    &:last-child{margin-right:0;}
    &.homeLink, &.mob{display:none;}
  }

  .mobContact, .mobNav{display:none;}
  // .mobNav{position:absolute; left:0;top:40px;}
  .mobNav{float:left;}//text-align:left;}
  .menuBtn{padding:0;
    justify-content:left;
    svg{width:30px;height:30px;color:$navy;}
  }

  a{color:$navy;}

  @include bp(dt){
    .twWrap, .navItem.mid{display:none;}
    .conLink+.hdSocial{margin-left:15px;}
    .hdBottom{margin-top:7px;
      &, a{color:$teal;}
    }
  }
  @media (max-width:1550px){.medDef{max-width:1200px;}}
  @media (max-width:1300px){.hdEnd{margin-top:45px;}}
  @include bp(tw){padding-bottom:0;
    .hdCol{font-size:16px;
      .mainNav, .conLink, .clearfix{display:none;}
    }
    .hdEnd{margin-top:50px;}
    .hdSocial{margin-left:10px;
      svg{width:20px;height:20px;}
    }
    .navColor{margin-top:20px;padding:10px 0;background-color:$bg;}
    .navBlk{margin-left:-2%;}
    .hdContact{margin-right:-2%;}
  }
  @include bp(nn){padding-top:15px;
    // .hdWrap{width:95%;max-width:500px;position:relative;}
    // .mobNav{display:block;}
    // .logoBlk{@include sec-default();max-width:100%;
    //   a{width:95%;max-width:300px;@include center-block();}
    // }
    // .hdEnd, .navMid{display:none;}
    .navBlk, .dotLg, .hdSocial, .phoneTop, .navMid{display:none;}
    .hdWrap{max-width:600px;position:relative;}
    .mobNav, .hdContact{width:20%;}
    .mobNav{display:inline-block;margin-top:40px;}
    .hdContact{margin-top:45px;margin-right:0;font-size:15px;}
    .logoBlk{width:60%;
      a{width:95%;max-width:280px;@include center-block();}
    }
    .mobContact{display:flex;align-items:center;justify-content:center;grid-column-gap:10px;font-size:16px;
      ul{margin-top:1px;max-height:20px;}
    }
    .twWrap.navTw{@include sec-default();height:20px;
      .navColor{display:none;}
    }
    .dot{width:3px;height:3px;margin-top:2px;border-radius:50%;background-color:$navy;}
    .twWrap svg{width:20px;height:20px;}
  }
  @include bp(oc){
    // .logoBlk a{max-width:260px;}
    // .mobNav{margin-top:35px;}
    .mobNav{position:absolute;top:35px;margin-top:0;left:0;}
    .logoBlk{@include sec-default();max-width:100%;
      a{width:100%;max-width:260px;}
    }

    .hdContact{display:none;}
    .twWrap .mobContact{font-size:15px;}
  }
  @media (max-width:450px){
    .mobNav{top:30px;}
    .logoBlk a{max-width:240px;}
    .dot2, .dot2+ul{display:none;}
  }
  @media (max-width:375px){padding:15px 0;border-bottom:5px solid rgba($navy,.1);
    .logoBlk a{max-width:225px;}
    .mobNav{top:25px;}
    .twWrap{display:none;}
  }
}

.SideNavList{padding:40px 20px 0;width:100%;max-width:250px;height:100%;background:$navy;
  &, p{color:$white;}
  .SideNav{margin-top:10%;}
  .closeIcon {position:absolute;right:10px;top:15px;cursor:pointer;font-weight:normal;
    svg{width:30px;height:30px;}
  }

  .navItem, .dcItem{@include sec-default();margin:0 0 25px;}
  .navItem{font-size:19px;line-height:1em;
    &:hover{opacity:.7;color:inherit;}
    &.mob{display:block;}
    &.fw{display:none;}
  }
  .drContact{margin-top:15px;padding-top:30px;border-top:1px solid rgba($white, .3);
    a:not([type="button"]), p{font-size:16px;}
    svg{width:22px;height:22px;}
    ul li:first-child{margin-left:0;margin-right:10px;}
    a[type="button"]{color:$navy;}
  }
}
