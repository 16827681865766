@import '../../assets/global.scss';

.Events{
  .bookForm{padding:60px 0;text-align:center;position:relative;z-index:0;
    &::after{@include before();@include sec-bgimage('./images/grad.jpg');background-position: right top;z-index:-2;}
    &::before{@include before();background-color:rgba($bg, .95);}
    h3{font-size:28px;margin-bottom:10px;}
    .formWrap{padding:30px 40px;margin-top:20px;background-color:$white;box-shadow:0px 10px 30px rgba($black,.1);text-align:left;}
  }

  @include bp(tw){
    .bookForm{padding-top:40px;}
  }
  @include bp(oc){
    .bookForm{padding:25px 0 40px;
      h3{font-size:22px;margin-bottom:5px;}
      .formWrap{padding:10px 20px;margin-top:0px;}
    }
  }
}