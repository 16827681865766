@import '../../assets/global.scss';

.bodyBlock{@include sec-default(); padding:60px 0;
  .topRow{text-align:center;}
  h3{font-size:35px;margin-bottom:5px;
    span.thin{font-weight:300;}
  }
  h4{font-size:19px;font-weight:normal;margin-bottom:0;}
  p{font-size:20px;}
  .lg p{font-size:22px;}
  p:last-child{margin-bottom:0;}

  .col{display:inline-block;vertical-align:top;}
  .col1{float:left;}
  .col3{float:right;}

  $borderColor:rgba($navy,.2);  
  .colWrap{margin-top:40px;position:relative;z-index:0;}
  // .colWrap{display:flex;align-items:center;justify-content:space-between; grid-column-gap:15px;}
  &.border .colWrap::after{@include after();top:0;}
  &.circle{overflow:hidden;
    .topRow{position:relative;z-index:1;}
    .colWrap::after{@include abs();border-radius:50%;
      width:300px;height:300px;top:-50%;
    }
  }
  &.focLeft{
    &.border{
      .colWrap{padding:10px 0;}
      .colWrap::after{width:28%;left:0;border-right:1px solid $borderColor;}
    }
    &.circle{
      .colWrap::after{left:0;}
      .col1{text-align:center;}
    }
  }
  &.focRight{
    &.border .colWrap::after{width:26.5%;right:0;border-left:1px solid $borderColor;}
    &.circle{
      .colWrap::after{right:-3.5%;}
      .col3{text-align:center;}
    }
  }

  &.white{background:$white;}
  &.bg{background:$bg;
    &.circle .colWrap::after{background-color:darken($bg, 10%);}
  }
  &.navy{background:$navy;}
  &.teal{background:$teal;
    &.circle .colWrap::after{background-color:darken($teal, 12%);}
  }
  &.dark, &.teal, &.navy{
    &.border .colWrap::after{border-color:rgba($white,.2);}
    p, h3, h4, &{color:$white;}
  }

  &.ath1__KPKP__v1{
    .col1, .col2{width:35%;padding-top:10px;}
    .col1{margin-left:-2%;}
    .col2{margin-left:3%;}
    .col3{width:24%;margin-top:2%;}
  }
  &.ath1{
    &.circle .colWrap::after{top:-5.5%;right:-2.8%;}
    .medDef{max-width:1200px;}
    .col1{width:33%;}
    .col2{width:35%;margin-left:5%;}
    .col3{width:24%;max-width:240px;margin-top:7%;}
  }

  &.ath2{
    &.circle .colWrap::after{left:-2.5%;top:-35%;}
    .colWrap{padding:20px 0 40px;}
    .col1{width:25%;}
    .col2{width:30%;margin-left:9%;}
    .col3{width:31%;}
  }

  &.ev1.circle .colWrap::after{width:370px;height:360px; left:-3%; top:-28%;}

  // .topRow{
  //   &::before{
  //     @include before();width:85%;left:7.5%;
  //     @include sec-bgimage('./images/waveBorderW.png');background-size:35%;
  //   }
  //   .h3wrap{padding:0 15px;}
  // }
  // &.focLeft .topRow::before{background-position: right center;}
  // &.focRight .topRow::before{background-position: left center;}
  // &.teal .h3wrap{background-color:$teal;}
  // &.bg{
  //   .topRow::before{background-image: url('../../assets/images/waveBorderBg.png');}
  //   .h3wrap{background-color:$bg;}
  // }

  @include bp(dt) {
    // &.ath1 .medDef{max-width:1200px;}

    &.ev1{
      .col1{width:27%;margin-top:4%;margin-left:-1%;}
      .col2{width:35%;margin-left:6%;}
      .col3{width:32%;margin-right:-2%;}
    }
  }

  @include bp(tw){
    &.stack12 {
      .colOuter{max-width:900px;}
      .colWrap{margin-top:20px;}
      &.border{
        .colWrap{padding:0;}
        .colWrap::after{display:none;}
      }
      .col1{width:100%;max-width:800px;@include center-block();text-align:center;margin-top:0;margin-bottom:20px;}
      .col2, .col3{width:47.5%; margin:0;}
      .lg p{line-height:1.4em;}
    }

    &.circle{
      .colWrap::after{width:285px;height:285px;top:-45%;}
      &.stack12.focRight .colWrap::after{right:-20%;}
      &.stack12.focLeft .colWrap::after{left:-20%;top:30%;}
    }

    &.ath1{
      &.circle .colWrap::after{right:-3.3%;}
      .col1{width:37%;margin-left:-2%;}
      .col2{margin-left:3%;}
      .col3{width:26%;margin-right:-1%;}
    }
    &.ath2.circle .colWrap::after{left:-2%;}

  }
  @media (max-width:1050px){padding:30px 0;
    h3{font-size:30px;}
    .lg p{font-size:19px;}
    p{font-size:18px;}

    &.circle{
      .colWrap::after{width:270px;height:270px;top:-60%;}
    }

    &.ath1{
      &.circle .colWrap::after{top:-10%;right:-3%;}
      .col1{width:36%;}
      .col3{width:24%;max-width:215px;margin-right:0;}
    }
    &.ath2.circle .colWrap::after{top:-42%;}
  }
  @include bp(nn){
    h4{font-size:18px;}

    .colOuter{max-width:750px;}
    .colWrap{margin-top:20px;}
    &.border .colWrap::after{display:none;}

    &.circle{
      .colWrap::after{width:300px;height:300px;}//top:-15%;}
      &.focRight .colWrap::after{right:-20%; top:unset;bottom:-25%;}
      &.focLeft .colWrap::after{left:-20%;top:-25%;}
      &.stack12 .colWrap::after{display:none;}
    }

    &.focRight .col3, &.focLeft .col1{width:100%;margin-right:0;text-align:center;}
    &.focRight .col3, &.focRight.circle .col3{margin-top:20px;}
    &.focLeft .col1{margin-top:0;margin-bottom:20px;}
    &.focRight .col1, &.focRight .col2, 
    &.focLeft .col2, &.focLeft .col3{width:48%;margin:0;}

    &.focRight .col2{float:right;}
    &.focLeft .col2{float:left;}

    &.stack12 {
      .colOuter{max-width:700px;}
      .colWrap{margin-top:10px;}
      .col1{max-width:100%;}
      .col2, .col3{width:100%;text-align:center;}
      .col3{margin-top:15px;}
    }

    &.ath1{
      &.circle .colWrap::after{right:-14%;}
      .col3{max-width:100%;}
    }
    &.ath2 .colWrap{padding:0;}
  }
  @include bp(oc){padding:25px 0;
    h3{font-size:25px;}
    h4, p, .lg p{font-size:17px;}

    &.circle .colWrap::after{display:none;}

    .colWrap{margin-top:0px;}
    &.focRight .col1, &.focRight .col2, 
    &.focLeft .col2, &.focLeft .col3{width:100%;text-align:center;}
    &.focLeft .col3{margin-top:15px;}
  }
  @include bp(ph){padding:20px 0;
    h3{font-size:21px;}
  }
}