@import '../../assets/global';

.addr{
  p.withLink{margin-bottom:10px;}
  p.nospace{margin-bottom:0px;}
  p.nospace+.clearfixBtn{margin-top:10px;}

  br.noshow, br.noshow2{display:none;}
  span.dot{font-weight:bold;}

  @include bp(tw){
    &.conPage{
      br.noshow2{display:block;}
      .dot2{display:none;}
    }
  }
  @include bp(nn){
    &.conPage{
      br.noshow2{display:none;}
      .dot2{display:inline-block;}
    }
  }
  @include bp(fi){
    &.conPage{
      br.noshow, br.noshow2{display:block;}
      .dot1, .dot2{display:none;}
    }
  }
}