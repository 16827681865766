@import '../../assets/global.scss';

.Home{padding-top:30px;
  @include bp(tw){padding-top:0;}

  .griddy{padding-top:50px;margin-bottom:100px;position:relative;z-index:0;
    &::before{@include abs();width:92%;height:90%;right:0;top:0;background-color:$tealLt;}

    .grImage, .grText{display:inline-block;}
    .i1{width:67%;float:left; margin-top:40px;}
    .i2{float:right;width:30%;
      img{max-height:530px;width:auto; @include center-block();}
    }
    .grText{margin-left:4%;margin-top:80px;}
    .i3{float:right;width:57%; margin-top:-120px;}
    .mobileText{display:none;}

    .nameHd{font-size:19px;margin-bottom:7px;font-weight:600;}
    .mainHd{font-size:40px;font-family:$body;margin-bottom:5px;}
    .soon{font-size:30px;font-weight:600;text-transform:uppercase;letter-spacing:3px;}

    @media (max-width:1600px){
      .i3{margin-top:-75px;}
    }
    @media (max-width:1500px){
      .mainHd{font-size:37px;}
      .soon{font-size:27px;}
      .i1{width:71%;}
      .i2{width:25%;
        img{max-height:500px;}
      }
      .i3{width:55%;}
    }
    @media (max-width:1300px){padding-top:40px;
      .nameHd{font-size:18px;}
      .mainHd{font-size:34px;}
      .soon{font-size:25px;}
      .i1{width:74%;}
      .i2{width:22%;}
      .grText{margin-top:60px;margin-left:3%;}
      .i3{margin-top:-110px;}
    }
    @include bp(tw){margin-bottom:60px;text-align:center;
      &::before{height:72%;top:12%;}
      .nameHd{font-size:17px;}
      .mainHd{font-size:32px;}
      .soon{font-size:21px;}
      .i1{width:95%;margin:0 0 40px;}
      .i3, .grText{display:none;}
      .i2{float:left;width:30%;max-width:255px;margin-left:12%;}
      .mobileText{margin-left:8%;margin-top:50px;display:block;float:left;}
    }
    @include bp(nn){margin-bottom:40px;
      &::before{width:90%;}
      .nameHd{font-size:16px;font-weight:normal;letter-spacing:1px;}
      .mainHd{font-size:29px;}
      .soon{font-size:19px;letter-spacing:2px;}
      .i1{margin-bottom:25px;}
      .i2{margin-left:0;}
      .mobileText{margin-left:10%;}
    }
    @include bp(md){
      .i1{width:97%;}
      .mobileText{margin-left:6%;}
    }
    @include bp(og){padding-top:20px;
      .nameHd{letter-spacing:0px;}
      .mainHd{font-size:27px;}
      .soon{font-size:18px;letter-spacing:1px;}
      .mobileText{margin-left:4%;margin-top:30px;}
    }
    @include bp(oc){padding-bottom:10px;margin-bottom:0px;//padding-top:20px;
      &::before{top:unset;bottom:0;width:100%;}
      .i1{@include center-block();}
      .nameHd{font-size:15px;}
      .mainHd{font-size:22px;}
      .soon{font-size:15px;letter-spacing:0px;}
      .mobileText{width:100%;margin-top:0;margin-left:0;}
      .i2{display:none;}
    }
  }

  .bodyBlock{@include sec-default(); padding:100px 0; overflow:hidden;
    $borderColor:rgba($navy,.2);  
    &.bg{background:$bg;
      .colWrap::after{background-color:darken($bg, 10%);}
    }
    &.navy{background:$navy;}
    &.teal{background:$teal;
      .colWrap::after{background-color:darken($teal, 12%);}
    }
    &.gold{background:lighten($gold, 20%);
      .colWrap::after{background-color:darken($gold, 10%);}
    }
    &.teal, &.navy{
      p, h3, h4, &{color:$white;}
    }

    .medDef{max-width:1100px;}
    .colWrap{position:relative;z-index:0;
      display:flex;align-items:center;justify-content:space-between;
      &::after{@include abs();border-radius:50%; width:300px;height:300px;top:-35%;}
    }
    .col2{max-width:580px;}
    .btnCol{min-width:150px;max-width:150px;}
    .btnRow{display:none;}

    &.focLeft .colWrap::after{left:-12%;}
    &.focRight .colWrap::after{right:-15%;}

    p{margin-bottom:0;}
    .big p{font-size:90px;letter-spacing:5px;text-transform:uppercase;line-height:1em;font-weight:900;
      span.less{letter-spacing:2px}
    }

    @media (min-width:990px){
      &.focRight .colWrap{flex-direction:row-reverse;}
    }
    @include bp(dt){
      .scLink{@include sec-default();padding-left:5px;padding-right:5px;}
      .scLink+.scLink{margin-left:0;margin-top:15px;}
    }

    @media (max-width:1500px){
      &.focLeft{
        .medDef{float:right;margin-right:6%;}
        .colWrap::after{left:-9%;}
      }
      &.focRight{
        .medDef{float:left;margin-left:6%;}
      }
    }
    @include bp(tx){
      .btnCol{min-width:135px;max-width:135px;}
      .big p{font-size:82px;}
      .scLink{font-size:15px;}
      
      .colWrap::after{width:275px;height:270px;top:-30%;}
      .col2{max-width:100%;padding:0 50px;}
      &.focLeft{
        .medDef{margin-right:4%;}
      }
      &.focRight{
        .medDef{margin-left:4%;}
        .colWrap::after{right:-12%;}
      }
    }
    @include bp(tw){padding:80px 0;
      .btnCol{display:none;}
      .btnRow{@include sec-default();margin-top:20px;}
      .scLink{padding:5px 20px;}
      .scLink{font-size:14.5px;}
      
      .colWrap{grid-gap:30px;}
      .colWrap::after{width:180px;height:180px;top:-25%;}
      .col2{padding:0px;
        p{width:95%;}
      }
      .medDef{max-width:900px;}
      &.focLeft{
        .medDef{float:none;margin-right:auto;}
        .col2 p{float:right;}
        .scLink{margin-left:5%;}
        .colWrap::after{left:-6%;}
      }
      &.focRight{
        .medDef{float:none;margin-left:auto;}
        .colWrap::after{right:-6%;top:-15%;}
      }
    }
    @include bp(tb){      
      .medDef{max-width:850px;}
      &.focRight{
        .col1{margin-right:2%;}
        .colWrap::after{right:-4%;}
      }
    }
    @include bp(nn){padding:50px 0 40px;text-align:center;
      p{font-size:18px;}
      .big p{font-size:55px;letter-spacing:0px;
        span.less{letter-spacing:0;}
      }
      
      .colWrap{grid-gap:10px;flex-direction:column;}
      // .colWrap::after{width:150px;height:145px;left:50%;margin-left:-150px;top:-12%;}
      .colWrap::after{width:130px;height:125px;left:50%;margin-left:-130px;top:-30px;}
      .col2 p{width:100%;}
      .medDef{max-width:700px;}
      &.focLeft{
        .scLink{margin-left:0;}
        .colWrap::after{left:inherit;}
      }
      &.focRight{
        .col1{margin-right:0;}
        .colWrap::after{right:unset;top:-30px;margin-left:-115px;}
      }
    }

    @include bp(oc){padding:35px 0 30px;
      .big p{font-size:25px;
        br{display:none;}
      }
      .colWrap::after{width:60px;height:60px;margin-left:-130px;top:-20px;}
      // .colWrap::after{width:65px;height:65px;margin-left:-180px;top:-15px;}
      &.focRight .colWrap::after{top:-20px;margin-left:-75px;}
    }

    @media (max-width:300px){
      // .big p{font-size:30px;}
      // .colWrap::after{margin-left:-160px;}
      .scLink+.scLink{max-width:130px;@include center-block();margin-top:15px;padding:5.5px 15px;}
    }
  }
}
