@import '../../assets/global';

.Footer{@include sec-default();padding:10px 0;
  .ctaBanner{padding:40px 80px;margin:40px 0 20px;position:relative;z-index:0;overflow:hidden;
    background-color:#56447E;
    &::after{@include after();width:300px;height:300px;right:-12%;background-color:rgba($white,.2);border-radius:50%;}

    .text, .btnWrap{display:inline-block;}
    .btnWrap{float:right;margin-top:3%;}

    p{margin-bottom:0;color:$white;}
    .hd{font-size:30px;font-weight:bold;}
    .sub{font-size:24px;letter-spacing:.5px;line-height:1.3em;}
  }

  .ftCol{display:inline-block;vertical-align:top; margin:0 20px;}
  p.copy{font-size:13.6px;font-weight:300;margin:5px 0 0;line-height:1.5em; text-align:center;
    br{display: none;}
  }

  @include bp(nn){
    .ctaBanner{padding:30px 20px 35px;
      .hd{font-size:24px;}
      .sub{font-size:20px;letter-spacing:0;}
    }
  }
  @include bp(og){
    .ctaBanner{padding:20px 5px 30px;margin:10px 0;text-align:center;
      .hd{font-size:21px;}
      .sub{font-size:18px;}
      .btnWrap{@include sec-default();}
    }
  }
  @include bp(oc){
    p.copy br{display: block;}
  }
  @include bp(fi){
    .ctaBanner{
      .hd{font-size:19px;line-height:1.3em;margin-bottom:3px;}
      .sub{font-size:17px;}
      .btnWrap{margin-top:15px;}
    }
    p.copy br{display: none;}
  }
}