@import "../../assets/global";

.Form{@include sec-default();
  .formGroup{@include sec-default(); margin:15px 0;}
  .formGroup.combo{display:flex;justify-content: space-between;grid-column-gap:20px;}
  .formGroupSub{width:100%;}
  .formGroupSub.phone{max-width:200px;}
  
  label{line-height:1.4em; margin-bottom:3px;}
  input, textarea{
    width:100%;
    padding:7px 5px 5px 7px;
    border:0; border-bottom:3px solid rgba($navy, .1);
    font-size:15px;
    font-family:$body;
    &:focus{background-color:darken($fa, 2%); outline:none; border-color:rgba($teal, .6);}
  }
  input{min-height:25px;}
  textarea{min-height:100px;}

  &.fa{
    input, textarea{background-color:$fa;}
  }
  &.white{
    input, textarea{background-color:$white;}
  }

  @include bp(og){
  .formGroup.combo{flex-direction:column;grid-gap:30px;}
  .formGroupSub.phone{max-width:100%;}
  }
}