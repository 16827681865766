@import './variables.scss';
@import './mixins.scss';

@font-face {
  font-family: 'GearedSlab-Bold';
  src: url('./fonts/GearedSlab-Bold.eot');
  src: url('./fonts/GearedSlab-Bold.eot?#iefix') format('embedded-opentype'),
       url('./fonts/GearedSlab-Bold.otf') format('otf'),
       url('./fonts/GearedSlab-Bold.woff') format('woff'),
       url('./fonts/GearedSlab-Bold.woff2') format('woff2');
}

html {font-size:17px;color:$navy;font-family:$body;}//background-color:$bg2;}
body{margin:0;}

h1, h2, h3, h4, h5, p{margin-top:0;color:$navy;font-family:inherit;
  &.up, span.up{text-transform:uppercase;}
  &.cap, span.cap{text-transform:capitalize;}
  &.em, span.em{font-style:italic;}
  &.cat, span.cat{font-size:19px;font-family:$body;text-transform:uppercase;letter-spacing:2px;font-weight:normal;line-height:1.1em;margin-bottom:0;}
  &.navy, span.navy, b.navy{color:$navy;}
  &.teal, span.teal, b.teal{color:$teal;}
  &.gold, span.gold, b.gold{color:$gold;}
  &.white, span.white, b.white{color:$white;}
  &.thin, span.thin{font-weight:300;}
}
.bold{font-weight:bold;}
h1, h2{line-height:1.2em;}
h1{font-size:45px;font-family:$gear;margin-bottom:inherit;}
p, h4, h5{margin-bottom:20px;}
p{
  font-size:19px;
  line-height:1.6em;
}

a{text-decoration:none;
  &:hover{opacity:.8;text-decoration:none;@include ease(.3s);}
  &:not(.scLink){color:inherit;}
}
button{background:none;border:none;border-radius:0;color:inherit;font-weight:inherit;font-family:inherit;
  font-size:inherit;letter-spacing:inherit;
  &:hover{cursor:pointer;color:inherit !important;}
}
img{@include center-block();max-width:100%;}
ul {margin:0;padding-left:0;}


.inside, .insideXl, .insideAlt, .insideTw, .medDef, .med2Def, .med3Def, .med4Def, .insideNrw{@include center-block();width:90%;max-width:1800px;} // 1700px
.insideXl{width:95%;}
.insideAlt{width:85%;}
.insideTw{max-width:1200px;}
.medDef{max-width:1000px;} // 1080px
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.med4Def{max-width:650px;}
@media screen and (min-width:823px){.insideNrw{max-width:820px;}}

.pageStyles, .fullRow, .fullSec{@include sec-default();}
.centerBlock{@include center-block();}
.textCenter{text-align:center;}

.clearfix::after, .clearfixBtn::after,.clearfixBtn2::after {content:"";clear:both;display:table;}
.clearfix.btn, .clearfixBtn{@include sec-default(); margin-top:15px;}

.scLink, .scLink2, .scLinkSm, .scLinkRev{
  &:not(.scLinkRev){color:$white;}
  border:2px solid $navy;text-align:center;
  font-size:16px;line-height:1em;letter-spacing:.3px;font-weight:600;@include tshadowl(rgba($black,.2));
  padding:8px 25px;box-shadow:0 10px 25px rgba($black,.15);
  position:relative;z-index:0;
  &::after{@include before();background-color:$white;z-index:-2;}
  &::before{@include before();background-color:$navy;}
  &:hover{text-decoration:none;color:$white !important;opacity:1;
    &::before{opacity:.85;}
  }

  &.full{@include sec-default();}
  &.up{text-transform:uppercase;}
  &+.scLink{margin-left:15px;}
  
  @include bp(tb) {font-size:15px;padding:6px 20px;}
  @include bp(og) {font-size:14px;}
}
.scLink2{border-color:$teal;
  &::before{background-color:$teal;}
}
.scLinkSm{font-size:15px;padding:4px 18px;}
.scLinkRev{color:$navy;text-shadow:none;
  &::before{background-color:$white;}
  &:hover{color:$navy !important;background-color:#ddd;}
}

.textLink{font-weight:900; position:relative;z-index:0;
  &::before, &::after{@include abs();left:-2%;height:6px;bottom:3px;}
  &::before{width:104%;background-color:rgba($navy,.2);}
  &::after{width:0;background-color: rgba($gold,1); transition: all .3s;}
  &:hover{opacity:1;
    &::after{width:104%;}
  }
}
