@import '../../assets/global.scss';

.Info{
  .introText{margin-top:20px;padding-bottom:70px;text-align:center;
    @include bp(tw){padding-bottom:50px;}
    @include bp(nn){padding-bottom:20px;
      .insideTw{max-width:750px;}
      p{font-size:17px;}
    }
    @include bp(og){margin-top:5px;padding-bottom:10px;}
  }
  .simpleText{padding:20px 0; text-align:center; background-color:$navy;
    p{color:$white;margin-bottom:0;font-weight:bold;line-height:1.4em;}
    @include bp(nn){p{font-size:17px;}}
    @media (max-width:550px){
      .medDef{max-width:350px;}
    }
  }

  .bodyBlockAlt{@include sec-default(); padding:100px 0; overflow:hidden;
    .colWrap{position:relative;z-index:0;display:flex;align-items:center;
      justify-content:center;grid-column-gap:50px;
      &::after{@include abs();border-radius:50%;width:260px;height:260px;}
    }
    $borderColor:rgba($navy,.2);  

    p{font-size:19px;}
    p:last-child{margin-bottom:0;}
  
    &.white .colWrap::after{background-color:$bg;}
    &.bg{background:$bg;
      .colWrap::after{background-color:darken($bg, 10%);}
    }
    &.navy{background:$navy;
      .colWrap::after{background-color:darken($navy, 12%);}
    }
    &.teal{background:$teal;
      .colWrap::after{background-color:darken($teal, 12%);}
    }
    &.teal, &.navy{
      p, h3, h4{color:$white;}
    }

    .big p{line-height:1em;font-weight:900;
      font-size:90px;letter-spacing:5px;text-transform:uppercase;
    }
    
    .scLink{@include sec-default(); font-size:15px;}
    .btnCol{min-width:150px;max-width:200px;}
    .blkLogo{min-width:250px;width:300px;}

    &.focLeft{
      .colWrap::after{left:-7%;}
      .col2{max-width:560px;}
    }
    &.focRight{
      .colWrap{flex-direction:row-reverse;}
      .colWrap::after{right:-7%;}
    }
    &.mvp .col2{max-width:600px;margin-right:-3%;}
    &.pivc .blkLogo{width:280px;}
    &.mvmt{
      .blkLogo{width:280px;}
      .col2{max-width:600px;margin-right:-2%;}
    }

    @media (max-width:1400px){
      .colWrap::after{width:230px;height:230px;}
      &.focLeft{
        .colWrap::after{left:-3%;}
        .blkLogo{width:270px;}
      }
      &.focRight .colWrap::after{right:-5%;}
    }
    @include bp(tw){
      .colWrap::after{width:200px;height:200px;}
      .scLink{font-size:14px;padding-left:15px;padding-right:15px;}
      .btnCol{min-width:130px;}
      p{font-size:18px;}

      &.focLeft .blkLogo{width:250px;}
      &.focRight{
        .colWrap::after{right:-3%;}
        .blkLogo{width:250px;}
        .col2{margin-right:0;}
      }
    }

    @include bp(nn){padding:25px 0;text-align:center;
      .colWrap{flex-direction:column;grid-column-gap:unset;grid-gap:20px;}
      .colWrap::after{display:none;}
      .blkLogo{min-width:unset;}
      .insideTw{max-width:700px;}
      p{font-size:17px;}

      &.focLeft{
        .blkLogo{width:220px;}
        .col2{max-width:100%;}
      }
      &.focRight{
        .colWrap{flex-direction:column;}
        .blkLogo{width:230px;}
        .col2{max-width:100%;}
      }
    }
  }
}