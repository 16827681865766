@import '../../assets/global.scss';

.pageIntro{padding:50px 0 60px;position:relative;text-align:center;
  .content{position:relative;}
  .list{max-width:1400px;@include center-block();margin-bottom:0;
    font-size:68px;font-family:$body;color:$tealLt;font-weight:900;opacity:.8;
  }
  .textbox{position:absolute;width:100%;left:0;top:30%;z-index:1;}
  .bigHd{font-size:80px;font-family:$fun;margin-bottom:0;}

  &.events .bigHd br{display:none;}
  &.athletics{padding-bottom:80px;
    .textbox{top:21%;}
    .bigHd{font-size:65px;}
    .text{margin-top:20px;}
  }
  &.info{padding-bottom:30px;
    .textbox{top:30%;}
    .bigHd{font-size:65px;}
  }

  @include bp(tx){
    .list{font-size:60px;}
    .bigHd.long{font-size:70px;}

    &.athletics .textbox{top:17%;}
  }
  @include bp(tw){
    .inside{width:95%;}
    .list{font-size:54px;}
    .bigHd{font-size:75px;}
    .bigHd.long{font-size:65px;}

    &.athletics .bigHd{font-size:60px;}
    &.info .bigHd{font-size:55px;}
  }
  
  @include bp(br){.list{font-size:52px;}}
  
  @include bp(nn){padding:30px 0 40px;
    .list{font-size:45px;}
    .bigHd{font-size:65px;}
    .bigHd.long{font-size:55px;}

    &.events{
      .textbox{top:10%;}
      .bigHd br{display:block;}
    }
    &.athletics{padding-bottom:60px;
      .bigHd{font-size:45px;}
      .text{margin-top:15px;}
    }
    &.info{padding-bottom:10px;
      .bigHd{font-size:40px;}
    }
  }
  @include bp(ft){
    .list{font-size:40px;}
    .bigHd{font-size:60px;}
    .bigHd.long{font-size:50px;}
  }
  @include bp(og){padding:20px 0 15px;overflow:hidden;
    .list{display:none;}
    .textbox{position:relative;top:auto;}
    .bigHd{font-size:40px;}
    .bigHd.long{font-size:35px;}

    &.athletics, &.events{
      .textbox{top:unset;}
    }
    &.athletics{padding-bottom:30px;
      .bigHd{font-size:30px;}
    }
    &.events .bigHd br{display:none;}
    &.info{padding-bottom:0px;
      .bigHd{font-size:30px;}
    }
  }
  @include bp(oc){
    .bigHd{font-size:30px;}
    .bigHd.long, &.athletics .bigHd{font-size:25px;}
  }
}